import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GetApp, GetAppBanner, GetAppDownloadBtn, GetAppSectionWrapper } from './getapp.styles';
// @ts-ignore
import Tilt from 'react-tilt';
import { AboveTitle, FlexWrapperStart, Title } from '../../../global/new-global-components';
import { NeutralStringObjectI } from '../../../types/NeutralObject.type';
import { gTagDataLayerPush } from '../../../utils/gTagDataLayerPush.function';
import { COUNTRY } from '../../../configs/env';

const onStoreBtnClick = (btn: string, elementPosition: number) => {
  const storeBtnClickEvents: NeutralStringObjectI = {
    playStore: 'web_play_store_button_click',
    appStore: 'web_app_store_button_click',
    huaweiStore: 'web_huawei_store_button_click',
  };

  gTagDataLayerPush({
    event: storeBtnClickEvents[btn],
    country: COUNTRY,
    pagePath: window.location.pathname,
    elementPosition: elementPosition,
    // timeSpentOnPage: '...' @TODO
  });

  console.log(`Triggered gTag data layer push for ${btn} - ${storeBtnClickEvents[btn]}`);
};

export const GetAppSection = (props: any) => {
  const { t } = useTranslation();
  const [iconAppGallery, setIconAppGallery] = useState('/vectors/app-gallery.svg');
  const [iconAppStore, setIconAppStore] = useState('/vectors/app-store.svg');
  const [iconGooglePlay, setIconGooglePlay] = useState('/vectors/google-play.svg');
  const {
    iosLink = t('link_ios-user-app'),
    androidLink = t('link_android-user-app'),
    huaweiLink = t('link_huawei-user-app'),
    side,
    backgroundImage,
    mobileBackgroundImage,
    style,
    elementPosition,
    customAboveTitle,
    customTitle,
  } = props;

  return (
    <GetAppSectionWrapper>
      <GetApp
        className="get-app-section section"
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
        style={{ ...style, justifyContent: side ? (side === 'left' ? 'flex-start' : 'flex-end') : 'center' }}
      >
        <Tilt options={{ max: 2, scale: 1, easing: 'cubic-bezier(.03,.98,.52,.99)', perspective: 400 }}>
          <GetAppBanner>
            <AboveTitle color="white" fontSize="20px" marginBottom="12px" textAlign="left">
              {customAboveTitle ? customAboveTitle : t('home_CTAsectionSubHeading')}
            </AboveTitle>
            <Title color="white" textAlign="left" fontSize="32px" maxWidth="400px">
              {customTitle ? (
                <Trans i18nKey={customTitle}>
                  part1 <span className="break-on-md-and-lg">part2</span>
                </Trans>
              ) : (
                t('home_CTAsectionHeading')
              )}
            </Title>
            <FlexWrapperStart className="get-app-buttons-flex-wrapper">
              <GetAppDownloadBtn
                onMouseOver={() => setIconAppStore('/vectors/app-store-hovered.svg')}
                onMouseOut={() => setIconAppStore('/vectors/app-store.svg')}
                onClick={() => {
                  onStoreBtnClick('appStore', elementPosition);
                }}
                href={iosLink}
                target="_blank"
              >
                <img src={iconAppStore} />
              </GetAppDownloadBtn>
              <GetAppDownloadBtn
                onMouseOver={() => setIconGooglePlay('/vectors/google-play-hovered.svg')}
                onMouseOut={() => setIconGooglePlay('/vectors/google-play.svg')}
                onClick={() => {
                  onStoreBtnClick('playStore', elementPosition);
                }}
                href={androidLink}
                target="_blank"
              >
                <img src={iconGooglePlay} />
              </GetAppDownloadBtn>
              <GetAppDownloadBtn
                onMouseOver={() => setIconAppGallery('/vectors/app-gallery-hovered.svg')}
                onMouseOut={() => setIconAppGallery('/vectors/app-gallery.svg')}
                onClick={() => {
                  onStoreBtnClick('huaweiStore', elementPosition);
                }}
                href={huaweiLink}
                target="_blank"
              >
                <img src={iconAppGallery} />
              </GetAppDownloadBtn>
            </FlexWrapperStart>
          </GetAppBanner>
        </Tilt>
      </GetApp>
    </GetAppSectionWrapper>
  );
};
