import { css } from '@emotion/core';
import { theme } from '../../../layouts/main.layout';

export const formTitleStyle = css`
  font-size: ${theme.fontSizes[3]}px;
  color: #000000;
  margin-bottom: 15px;
`;

export const inputStyle = {
  border: `none`,
  outline: 'none',
  borderRadius: '22px',
  height: '50px',
  backgroundColor: `#ffe9e9`,
  marginTop: '10px',
  display: 'block',
  padding: '0px 0px 0px 10px',
  width: '100%',
};

export const textareaInputStyle = {
  borderRadius: '8px',
  border: 'solid 1px',
  borderColor: `${theme.colors.borderDarkGray}`,
  padding: '5px 0px 0px 10px',
};

export const inputStyleExperience = css`
  outline: none;
  border: none;
  border-radius: 22px;
  height: 10rem;
  background-color: #ffe9e9;
  margin-top: 10px;
  display: block;
  padding: 10px 0px 0px 10px;
  width: 100%;
`;

export const headingStyle = css`
  font-size: ${theme.fontSizes[6]}px;
  color: 'white';
  font-weight: 900;
  margin-bottom: 15px;
`;

export const labelTextStyle = css`
  color: #333333;
  margin-top: 10px;
  font-size: ${theme.fontSizes[2]}px;
  text-align: justify;
`;

export const buttonStyle = {
  height: '50px',
  width: 'auto',
  borderRadius: '30px',
};
