import styled from '@emotion/styled';

export const GetAppSectionWrapper = styled.div`
  @media screen and (max-width: 370px) {
    .get-app-section {
      h2 {
        font-size: 24px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 28px;
    }

    h4 {
      font-size: 12px;
    }

    .get-app-section {
      padding: 40px 20px;
      padding-top: 200px;
    }

    button {
      width: 100%;
      height: 58px;

      img {
        height: 100%;
      }
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .get-app-section {
      padding: 40px 50px;
      padding-top: 200px;
      justify-content: center !important;
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    .get-app-section {
      padding: 50px !important;
    }
  }

  @media screen and (min-width: 1600px) {
    .get-app-section {
      padding: 200px 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .get-app-section {
      padding: 200px 200px;
    }
  }
`;

export const GetApp = styled.div<any>`
  height: auto;
  position: relative;
  background-color: #ffffff;
  background-size: 100% auto !important;
  height: auto;
  display: flex;
  align-items: center;
  padding: 280px 300px;
  background: ${(props: any) => `url(${props.backgroundImage}) no-repeat` || null};

  @media screen and (min-width: 769px) {
    padding: 100px 50px;
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    background-size: auto 100% !important;
    background-position-x: right !important;
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    a {
      width: 100%;

      img {
        width: auto !important;
      }
    }

    .get-app-buttons-flex-wrapper {
      flex-direction: row;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    padding: 80px 50px;
    .get-app-section {
      justify-content: center !important;
    }
  }

  @media screen and (min-width: 1400px) {
    padding: 280px 100px;
    padding-bottom: 70px;
  }

  @media screen and (min-width: 1500px) {
    padding: 280px 150px;
    padding-bottom: 70px;
  }

  @media screen and (min-width: 1600px) {
    padding: 280px 250px;
    padding-bottom: 70px;
    background-size: cover !important;
  }

  @media screen and (min-width: 2000px) {
    padding: 280px 200px;
    padding-bottom: 70px;
    background-size: cover !important;
  }

  /* @media screen and (max-width: 1792px) {
    padding: 280px 250px;
    padding-bottom: 70px;
  } */
  @media screen and (max-width: 768px) {
    background: ${(props: any) => `url(${props.mobileBackgroundImage}) no-repeat` || null};
    background-size: 100% auto !important;
  }
`;

export const GetAppBanner = styled.div`
  width: 758px;
  height: 360px;
  padding: 60px;
  background: #f47e20;
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100%;
    padding: 40px 30px;

    a {
      width: 100%;
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    width: 100%;

    h2 {
      width: 100%;
      max-width: 100%;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    height: auto;
    h2 {
      width: auto;
      max-width: 1000px;
    }
  }

  @media screen and (min-width: 1200px) {
    h2 {
      width: auto;
      max-width: 1000px;
    }
  }

  @media screen and (min-width: 1600px) {
    h2 {
      width: auto;
      max-width: 800px;
    }
  }

  @media screen and (min-width: 2000px) {
    height: auto;
    width: 1200px;
    h2 {
      width: auto;
      max-width: 1000px;
    }
  }
`;

export const GetAppAboveTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: white;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const GetAppTitle = styled.h2`
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 125%;
  color: white;
`;

export const GetAppDownloadWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const GetAppDownloadBtn = styled.a`
  width: 166.64px;
  height: 62px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-right: 20px;
  margin-top: 20px;
  border: 1px solid white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  && img {
    width: auto;
  }

  :hover svg {
    /* height: 68px;
    width: 166.64px; */
    fill: red;
    /* filter: invert(1) sepia(100%); */
  }

  :hover {
    background-color: white;
  }

  :hover path {
    /* filter: invert(100%); */
    /* fill: red !important; */
  }

  svg path {
    fill: white;
    transition: ease 0.3s;
  }

  @media screen and (max-width: 768px) {
    border-radius: 15px;
    margin-top: 10px;
    && img {
      width: 50%;
    }

    :hover {
      background-color: white;
    }

    :hover svg path {
      fill: #f47e20;
      transition: ease 0.3s;
    }

    :hover img {
    }
  }

  @media screen and (min-width: 2000px) {
    height: 70px;
    min-width: 200px;
  }

  @media screen and (min-width: 2200px) {
    height: 80px;
    min-width: 260px;

    && img {
      width: 140px;
    }
  }
`;

export const GetAppDownloadBtnIcon = styled.img`
  width: 100%;
  height: auto;
  transition: ease 0.3s;
`;
