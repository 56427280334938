import React from 'react';
import { css } from '@emotion/core';
import { ErrorMessage } from 'formik';

import { theme } from '../../layouts/main.layout';

const errorDiv = css`
  color: ${theme.colors.warningRed};
  font-size: 11px;
`;

interface ErrMessageProps {
  name: string;
}

export const CustomErrorMessage = ({ name }: ErrMessageProps) => (
  <ErrorMessage name={name} render={(msg) => <span css={errorDiv}>{msg}</span>} />
);
