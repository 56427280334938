import { VOZZiWindowI } from 'types/VOZZiWindow.interface';
import { NeutralObjectI } from '../types/NeutralObject.type';

declare let window: Window & VOZZiWindowI;

export const gTagDataLayerPush = (data: NeutralObjectI): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
  console.log('Pushed to GTM dataLayer: ', data);
};
